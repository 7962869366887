



























































































































import { ILocation, ISearchPlaceResult, ITencentOverlayListItem, IUpdateAreaData, IAreaInfo, ILoadAreaData } from '@/interface/common'
import { Component, Vue } from 'vue-property-decorator'
import { SearchPlace } from '@/api/tencent'

import { GetUser, UpdateArea, LoadArea, GetDepartments, GetAreas } from '@/api/common'
import store from '@/store'
import { IArea, IDepartment, IUser } from '@/interface/api'

@Component({
  name: 'Home'
})
export default class extends Vue {
  // 加载状态
  private loading = false
  private user : IUser = {
    CompanyId: '',
    DepartmentId: '',
    ITCode: '',
    Password: '',
    Name: '',
    IsValid: false,
    CreateTime: '',
    CreateBy: '',
    ID: ''
  }

  private radius = 5000
  // private activeIds : string[]= []
  private areas : IAreaInfo[] = []
  // 当前坐标
  private location : ILocation = {
    lat: 0,
    lng: 0
  }

  private mode = '未启用'
  // 搜索地点
  private keyword = ''
  // 腾讯地图
  private map : any = {}
  // 编辑器
  private editor : any | undefined = undefined
  // Logo
  private logo = require('@/static/logo.png')
  // 默认地图模式
  private radio = '地图'
  // 搜索候选列表
  private options : ISearchPlaceResult[] = []

  private activeAreaId = ''

  private selfOverlay : any = undefined
  private otherOverlay: any = undefined

  private async created() {
    if (this.$route.query && this.$route.query.token && this.$route.query.token.length === 36) {
      store.state.token = this.$route.query.token
      await this.getUser()
      await this.getDepartments()
      this.initMap()
      await this.getAreas()
      if (this.$route.query && this.$route.query.id) {
        const id = this.$route.query.id as string
        const area = this.areaList.find(e => e.ID === id)
        if (area) {
          this.activeDepartmentId = [area.DepartmentId]
          this.activeAreaId = area.ID
        }
        this.changeAreaHandler(id)
      }
      this.addMarker(this.areaList)
    } else {
      this.$message.error('权限不足')
    }
  }

  // 加载部门
  private async getUser() {
    const result = await GetUser()
    if (result.data?.Code === 200) {
      this.user = result.data.Data
      if (this.user?.Company?.LocationInfo) {
        const info = this.user.Company.LocationInfo.split(',')
        if (info.length === 2) {
          this.location.lat = parseFloat(info[0])
          this.location.lng = parseFloat(info[1])
        }
      }
    }
  }

  private departmentList : IDepartment[] = []
  private activeDepartmentId : string[] = []
  // 加载部门
  private async getDepartments() {
    const result = await GetDepartments()
    if (result.data?.Code === 200) {
      this.departmentList = result.data.Data
    }
  }

  private areaList: IArea[] = []
  // 加载地区
  private async getAreas() {
    const result = await GetAreas()
    if (result.data?.Code === 200) {
      this.areaList = result.data.Data
      this.initEditor2(this.areaList)
    }
  }

  // 初始化地图
  private initMap() {
    // eslint-disable-next-line no-undef
    this.map = new TMap.Map('map', {
      zoom: 16, // 设置地图缩放级别
      // eslint-disable-next-line no-undef
      center: new TMap.LatLng(this.location.lat, this.location.lng) // 设置地图中心点坐标
    })
  }

  // 设定地图模式
  private setBaseMap() {
    if (this.radio === '地图') {
      this.map.setBaseMap({
        type: 'vector',
        features: ['base', 'building3d', 'point', 'label']
      })
    }
    if (this.radio === '地形') {
      this.map.setBaseMap({
        type: 'traffic'
      })
    }
    if (this.radio === '卫星') {
      this.map.setBaseMap({
        type: 'satellite',
        features: ['base', 'road']
      })
    }
  }

  // 搜索词变更事件
  private searchSelectChangeHandler(id : string) {
    const item = this.options.find(e => e.Id === id)
    if (item) {
      this.location.lat = item.Location.Lat
      this.location.lng = item.Location.Lng
      this.setCenter()
    }
  }

  get activeAreaList() {
    return this.areaList.filter(e => e.DepartmentId === this.activeDepartmentId[this.activeDepartmentId.length - 1])
  }

  private changeDepartmentHandler() {
    this.activeAreaId = ''
  }

  // 地区变更事件
  private changeAreaHandler(id: string) {
    const overlay : ITencentOverlayListItem = this.editor.getActiveOverlay()
    if (overlay && overlay.overlay && overlay.overlay.geometries.length > 0) {
      const item = overlay.overlay.geometries.find((e : any) => e.id === id)
      if (item && item.paths && item.paths.length > 2) {
        // 激活已有图层
        // 设定编辑器为编辑模式
        // eslint-disable-next-line no-undef
        this.editor.setActionMode(TMap.tools.constants.EDITOR_ACTION.INTERACT)
        this.editor.setSelectable(true)
        const paths = item.paths
        const path = []
        for (let index = 0; index < paths.length; index++) {
          const element = paths[index]
          // eslint-disable-next-line no-undef
          path.push(new TMap.LatLng(element.lat, element.lng))
        }
        // 获取多边形矩阵中心点
        // eslint-disable-next-line no-undef
        const location = TMap.geometry.computeCentroid(path)
        this.location.lat = location.lat
        this.location.lng = location.lng
        this.setCenter()
        this.mode = '编辑模式'
        return
      }
    }
    // 设定编辑器为新增模式
    // eslint-disable-next-line no-undef
    this.editor.setActionMode(TMap.tools.constants.EDITOR_ACTION.DRAW)
    this.editor.setSelectable(false)
    this.mode = '新增模式'
  }

  // 设定地图中心点
  private setCenter() {
    // eslint-disable-next-line no-undef
    this.map.setCenter(new TMap.LatLng(this.location.lat, this.location.lng))
  }

  // 搜索候选词
  private async search(query: string) {
    if (query.trim().length > 0) {
      const result = await SearchPlace({
        keyword: query,
        location: this.location,
        region: this.user.Company?.Region
      })
      if (result.data?.Code === 200) {
        this.options.splice(0, this.options.length)
        result.data.Data.forEach(element => {
          this.options.push(element)
        })
      }
    }
  }

  // 保存地图
  private async save() {
    // eslint-disable-next-line no-undef
    this.editor.setActionMode(TMap.tools.constants.EDITOR_ACTION.DRAW)
    // eslint-disable-next-line no-undef
    this.editor.setActionMode(TMap.tools.constants.EDITOR_ACTION.INTERACT)
    const list = this.selfOverlay.geometries
    const item = list.find((e : any) => e.id === this.activeAreaId)
    if (item) {
      if (item.paths.length > 2) {
        const paths = item.paths
        const path = []
        for (let index = 0; index < paths.length; index++) {
          const element = paths[index]
          // eslint-disable-next-line no-undef
          path.push(new TMap.LatLng(element.lat, element.lng))
        }
        // 获取多边形矩阵中心点
        // eslint-disable-next-line no-undef
        const location = TMap.geometry.computeCentroid(path)
        const data : IUpdateAreaData = {
          id: item.id,
          location: location,
          paths: item.paths
        }
        try {
          const result = await UpdateArea(data)
          if (result.data) {
            if (result.data?.Code === 200) {
              this.$message.success('保存成功')
            } else {
              this.$message.error('保存失败, ' + result.data.Message)
            }
          } else {
            this.$message.error('保存失败, 网络故障')
          }
        } catch (error) {
          this.$message.error('保存失败, 网络故障')
        }
      } else {
        this.$message.error('保存失败, 绘制区域为空')
      }
    } else {
      this.$message.error('请选择编辑区域')
    }
  }

  // 重新加载地图
  private async reload() {
    window.location.reload()
  }

  // 初始化地图编辑器
  private async initEditor(area: IAreaInfo) {
    const overlayList = []
    if (area && area.children) {
      for (let index = 0; index < area.children.length; index++) {
        const element = area.children[index]
        // 如果地图已经绘制,添加到几何图层
        if (element.polygon.length > 0) {
          const geometry :IUpdateAreaData = JSON.parse(element.polygon)
          const list = []
          for (let index = 0; index < geometry.paths.length; index++) {
            const element = geometry.paths[index]
            // eslint-disable-next-line no-undef
            list.push(new TMap.LatLng(element.lat, element.lng))
          }
          // eslint-disable-next-line no-undef
          const overlay = new TMap.MultiPolygon({
            map: this.map,
            styles: {
              // eslint-disable-next-line no-undef
              highlight: new TMap.PolygonStyle({
                color: 'rgba(255, 255, 0, 0.6)'
              })
            },
            geometries: [{
              id: element.id,
              paths: list
            }]
          })
          overlayList.push({
            overlay: overlay,
            name: element.area,
            id: element.id,
            drawingStyleId: 'default',
            selectedStyleId: 'highlight'
          })
          continue
        }
        // 如果没有绘制, 添加空白图层到编辑器
        // eslint-disable-next-line no-undef
        const overlay = new TMap.MultiPolygon({
          map: this.map,
          styles: {
            // eslint-disable-next-line no-undef
            highlight: new TMap.PolygonStyle({
              color: 'rgba(255, 255, 0, 0.6)'
            })
          }
        })
        overlayList.push({
          overlay: overlay,
          name: element.area,
          id: element.id,
          drawingStyleId: 'default',
          selectedStyleId: 'highlight'
        })
      }
    }
    if (this.editor === undefined) {
      // 如果地图编辑器不存在, 则初始化地图编辑器
      // eslint-disable-next-line no-undef
      this.editor = new TMap.tools.GeometryEditor({
        map: this.map, // 编辑器绑定的地图对象
        overlayList: overlayList,
        // eslint-disable-next-line no-undef
        actionMode: TMap.tools.constants.EDITOR_ACTION.INTERACT, // 编辑器的工作模式
        snappable: true, // 开启吸附
        selectable: true
      })

      // 监听绘图完成事件
      this.editor.on('draw_complete', () => {
      // eslint-disable-next-line no-undef
        this.editor.setActionMode(TMap.tools.constants.EDITOR_ACTION.INTERACT)
        this.editor.setSelectable(true)
        this.mode = '编辑模式'
      })

      // 监听编辑开始事件
      this.editor.on('active_changed', () => {
        const overlay : ITencentOverlayListItem = this.editor.getActiveOverlay()
        if (overlay) {
          const area = this.areaList.find(e => e.ID === overlay.id)
          if (area) {
            this.activeDepartmentId = [area.DepartmentId]
            this.activeAreaId = area.ID
          }
        }
      })
      // 监听删除完成事件
      this.editor.on('delete_complete', () => {
      // eslint-disable-next-line no-undef
        this.editor.setActionMode(TMap.tools.constants.EDITOR_ACTION.DRAW)
        this.editor.setSelectable(false)
        this.mode = '新增模式'
      })
    } else {
      // 如果地图编辑器已经存在, 添加几何图层
      overlayList.forEach(element => {
        this.editor.addOverlay(element)
      })
    }
  }

  private async loadAreas() {
    const location = this.map.getCenter()
    const data : ILoadAreaData = {
      lng: location.lng,
      lat: location.lat,
      radius: this.radius
    }
    const result = await LoadArea(data)
    if (result.data?.Code === 200) {
      this.addMarker(result.data.Data)
      const geometries = []
      for (let i = 0; i < result.data.Data.length; i++) {
        const element = result.data.Data[i]
        if (element.ID === this.activeAreaId) continue
        if (element.Location && element.Location.length > 0) {
          if (this.selfOverlay !== undefined) {
            const item = this.selfOverlay.geometries.find((e : any) => e.id === element.ID)
            if (item) continue
          }
          if (this.otherOverlay !== undefined) {
            const item = this.otherOverlay.geometries.find((e : any) => e.id === element.ID)
            if (item) continue
          }
          const geometry :IUpdateAreaData = JSON.parse(element.Location)
          const list = []
          for (let index = 0; index < geometry.paths.length; index++) {
            const element = geometry.paths[index]
            // eslint-disable-next-line no-undef
            list.push(new TMap.LatLng(element.lat, element.lng))
          }
          geometries.push({
            id: element.ID,
            paths: list,
            styleId: 'highlight'
          })
        }
      }
      // 加载区域
      if (this.otherOverlay === undefined) {
        // eslint-disable-next-line no-undef
        this.otherOverlay = new TMap.MultiPolygon({
          map: this.map,
          styles: {
            // eslint-disable-next-line no-undef
            highlight: new TMap.PolygonStyle({
              color: 'rgba(112, 193, 179, 0.6)',
              showBorder: true,
              borderDashArray: [4, 2]
            })
          },
          geometries: geometries
        })
      } else {
        for (let index = 0; index < geometries.length; index++) {
          const element = geometries[index]
          this.otherOverlay.add(element)
        }
      }
      this.$message.success('周边地区加载成功')
    } else {
      this.$message.error('周边地区加载失败')
    }
  }

  private clearAreas() {
    this.otherOverlay.setMap(null)
    this.otherOverlay = undefined
  }

  private addMarker(areas : IArea[]) {
    const geometries = []
    for (let i = 0; i < areas.length; i++) {
      const area = areas[i]
      if (!area.Location) continue
      const info : IUpdateAreaData = JSON.parse(area.Location)
      geometries.push({ // 点标注数据数组
        id: area.ID,
        styleId: 'marker',
        // eslint-disable-next-line no-undef
        position: new TMap.LatLng(info.location.lat, info.location.lng),
        content: `[${area.Department?.Name}]-[${area.Name}]-[${area.Engineer?.Name}]`
      })
    }
    // 添加标记
    // eslint-disable-next-line no-undef
    const marker = new TMap.MultiMarker({
      id: areas[0].ID, // 图层id
      map: this.map,
      styles: { // 点标注的相关样式
        // eslint-disable-next-line no-undef
        marker: new TMap.MarkerStyle({
          width: 35,
          height: 35,
          anchor: { x: 16, y: 32 },
          src: '//manage.zhaodiangong.com/pos.png',
          direction: 'bottom'
        })
      },
      geometries: geometries
    })
  }

  // 初始化地图编辑器
  private async initEditor2(areas: IArea[]) {
    const geometries = []
    for (let i = 0; i < areas.length; i++) {
      const element = areas[i]
      if (element.Location && element.Location.length > 0) {
        const geometry :IUpdateAreaData = JSON.parse(element.Location)
        const list = []
        for (let index = 0; index < geometry.paths.length; index++) {
          const element = geometry.paths[index]
          // eslint-disable-next-line no-undef
          list.push(new TMap.LatLng(element.lat, element.lng))
        }
        geometries.push({
          id: element.ID,
          paths: list
        })
        continue
      }
    }

    // 如果地图编辑器不存在, 则初始化地图编辑器
    // eslint-disable-next-line no-undef
    this.selfOverlay = new TMap.MultiPolygon({
      map: this.map,
      styles: {
        // eslint-disable-next-line no-undef
        highlight: new TMap.PolygonStyle({
          color: 'rgba(255, 255, 0, 0.6)'
        })
      },
      geometries: geometries
    })

    // eslint-disable-next-line no-undef
    this.editor = new TMap.tools.GeometryEditor({
      map: this.map, // 编辑器绑定的地图对象
      overlayList: [{
        overlay: this.selfOverlay,
        id: 'self',
        drawingStyleId: 'default',
        selectedStyleId: 'highlight'
      }],
      // eslint-disable-next-line no-undef
      actionMode: TMap.tools.constants.EDITOR_ACTION.INTERACT, // 编辑器的工作模式
      snappable: true, // 开启吸附
      selectable: true
    })

    // 监听绘图完成事件
    this.editor.on('draw_complete', (element : any) => {
      this.selfOverlay.remove(element.id)
      element.id = this.activeAreaId
      this.selfOverlay.add(element)
      // eslint-disable-next-line no-undef
      this.editor.setActionMode(TMap.tools.constants.EDITOR_ACTION.INTERACT)
      this.editor.setSelectable(true)
      this.mode = '编辑模式'
    })

    // 监听编辑开始事件
    this.editor.on('select', () => {
      const list = this.editor.getSelectedList()
      if (list.length > 1) {
        // eslint-disable-next-line no-undef
        this.editor.setActionMode(TMap.tools.constants.EDITOR_ACTION.DRAW)
        // eslint-disable-next-line no-undef
        this.editor.setActionMode(TMap.tools.constants.EDITOR_ACTION.INTERACT)
      }
      if (list.length === 1) {
        const element = list[0]
        if (element) {
          const area = this.areaList.find(e => e.ID === element.id)
          if (area) {
            this.activeDepartmentId = [area.DepartmentId]
            this.activeAreaId = area.ID
          }
        }
      }
    })
    // 监听删除完成事件
    this.editor.on('delete_complete', () => {
      // eslint-disable-next-line no-undef
      this.editor.setActionMode(TMap.tools.constants.EDITOR_ACTION.DRAW)
      this.editor.setSelectable(false)
      this.mode = '新增模式'
    })
  }
}
