import { IApiResult, ISearchPlaceQuery, ISearchPlaceResult } from '@/interface/common'
import request from '@/untils/request'
import { AxiosPromise } from 'axios'

export const SearchPlace = (data: ISearchPlaceQuery) : AxiosPromise<IApiResult<ISearchPlaceResult[]>> =>
  request({
    url: '/api/tencent/search',
    method: 'post',
    data
  })
