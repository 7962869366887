import { IApiResult, IArea, IDepartment, IUser } from '@/interface/api'
import { ILoadAreaData, IUpdateAreaData } from '@/interface/common'
import request from '@/untils/request'
import { AxiosPromise } from 'axios'

export const GetUser = () : AxiosPromise<IApiResult<IUser>> =>
  request({
    url: '/api/Draw/GetUser',
    method: 'get'
  })

export const GetAreas = () : AxiosPromise<IApiResult<IArea[]>> =>
  request({
    url: '/api/Draw/GetAreas',
    method: 'get'
  })

export const GetDepartments = () : AxiosPromise<IApiResult<IDepartment[]>> =>
  request({
    url: '/api/Draw/GetDepartments',
    method: 'get'
  })

export const UpdateArea = (data: IUpdateAreaData) : AxiosPromise<IApiResult<any>> =>
  request({
    url: '/api/Tencent/UpdateArea',
    method: 'post',
    data
  })

export const LoadArea = (data: ILoadAreaData) : AxiosPromise<IApiResult<IArea[]>> =>
  request({
    url: '/api/Tencent/WechatLoad',
    method: 'post',
    data
  })
