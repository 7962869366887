import axios from 'axios'
import store from '@/store'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000
})

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // Add X-Access-Token header to every request, you can add other custom headers here
    // config.headers['X-Access-Token'] = UserModule.token
    config.headers.token = store.state.token
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// Response interceptors
service.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return response
    }
    return Promise.reject(response)
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default service
